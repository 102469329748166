import KTDatatable from '../scripts.bundle';
if (window.location.origin.includes("localhost")) {
    var sitelink = "http://localhost/nijhuis/public";
} else {
    var sitelink = window.location.origin;
}

$(document).ready(function(){
    if($('#projecten_table').length > 0){
        projecten_table();
    }
    if($('#subcategorie_detail').length > 0){
        subcategorie_detail();
    }
    if($('#leverancier_table').length > 0){
        leverancier_table();
    }
    if($('#artikelen_table').length > 0){
        artikelen_table();
    }
    if($('#hoofdcategorie_detail').length > 0){
        hoofdcategorie_detail();
    }
    if($('#subprojecten_table').length > 0){
        subprojecten_table();
    }
    if($('#gebruikers_table').length > 0){
        gebruikers_table();
    }
    if($('#opdrachtgevers_table').length > 0){
        opdrachtgevers_table();
    }
    if($('#status_table').length > 0){
        status_table();
    }
    if($('#logs_table').length > 0){
        log_table();
    }

});
function gebruikers_table(){
    var gebruikers_table_url = sitelink+"/dt/users";

    $('#gebruikers_table').DataTable({
        stateSave: true,
        "pageLength": 25,
        order: [[2, "asc"]],
        responsive: true,
        "ajax": gebruikers_table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Id"},
            {title: "Naam"},
            {title: "E-mail"},
            {title: "Role"},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}

function log_table(){
    var logs_table_url = sitelink+"/dt/logs";

    let table = $('#logs_table').DataTable({
        stateSave: true,
        "pageLength": 25,
        "serverSide": true,
        order: [[2, "asc"]],
        responsive: true,
        "ajax": logs_table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {data: 'id', title: "Id"},
            {data: 'type', title: "Type"},
            {data: 'changes', title: "Veranderingen"},
            {data: 'created_at', title: "Gemaakt op"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });

    $('#logs_table tbody').on('click', 'tr', function () {
        let tr = $(this).closest('tr');
        let row = table.row(tr);

        let data = row.data();

        if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
        } else {
            // Open this row
            row.child('').show();
            tr.addClass('shown');
        }
    });


}

function hoofdcategorie_detail(){
    var table_url = sitelink+"/dt/categorie/detail/getSub/"+$('#hoofdcategorie_detail').data('main');

    $('#hoofdcategorie_detail').DataTable({
        stateSave: true,
        "pageLength": 25,
        order: [[0, "asc"]],
        responsive: true,
        "ajax": table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Id"},
            {title: "Name"},
            {title: "Acties"},
        ],

        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}
function subcategorie_detail(){
    var table_url = sitelink+"/dt/categorie/detail/getArtikelen/"+$('#subcategorie_detail').data('main');

    $('#subcategorie_detail').DataTable({
        stateSave: true,
        "pageLength": 25,
        order: [[0, "asc"]],
        responsive: true,
        "ajax": table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Artikelnummer"},
            {title: "Naam"},
            {title: "Leverancier"},
            {title: "Subcategorie"},
            {title: "Hoofdcategorie"},
            {title: "Omschrijving"},
            {title: "Acties"},
        ],

        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}
function opdrachtgevers_table() {
    var opdrachtgevers_table_url = sitelink + "/dt/opdrachtgevers";
    $('#opdrachtgevers_table').DataTable({
        stateSave: true,
        responsive: false,
        "pageLength": 25,
        order: [[2, "asc"]],
        "ajax": opdrachtgevers_table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Id"},
            {title: "Opdrachtgever"},
            // {title: "E-mail"},
            {title: "Plaats"},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden ",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#opdrachtgevers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#opdrachtgevers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}
function status_table() {
    var table_url = sitelink + "/dt/statussen";
    $('#status_table').DataTable({
        stateSave: true,
        responsive: false,
        "pageLength": 25,
        order: [[2, "asc"]],
        "ajax": table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Id"},
            {title: "Naam"},
            {title: "Kleurcode"}
        ],
        // "columnDefs": [
        //     {
        //         sClass: "hidden ",
        //         visible: false,
        //         "targets": [0],
        //         searchable: true,
        //     },
        // ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            // $("#opdrachtgevers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            // $("#opdrachtgevers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}

function leverancier_table() {
    var leverancier_table_url = sitelink + "/dt/leveranciers";
    $('#leverancier_table').DataTable({
        stateSave: true,
        responsive: false,
        "pageLength": 25,
        order: [[2, "asc"]],
        "ajax": leverancier_table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Id"},
            {title: "Leverancier"},
            {title: "E-mail"},
            {title: "Plaats"},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden ",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {




            var html = $('#leveranciers_table_filters tr');
            $('#leveranciers_table_filters').remove();
            $('#leverancier_table > thead').append(html);
            $('#leveranciers_table_filters').removeClass('d-none');

            $('#leveranciers_table_filters_submit').on('click', function(){
                var filters = $('#leveranciers_table_filters_form').serializeArray();
                var new_data_url = leverancier_table_url+'?'+$.param(filters);

                $('#leverancier_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#leveranciers_table_filters_submit').click();
                }
            });
            $('#leveranciers_table_filters_reset').on('click', function(){
                console.log(leverancier_table_url);
                $('#leveranciers_table_filters_form')[0].reset();
                $('#leverancier_table').DataTable().ajax.url(leverancier_table_url).load();
            });


            $("#leverancier_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#leverancier_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}
function projecten_table() {
    var table_url = sitelink + "/dt/projecten";
    if($('#projecten_table').data('opdrachtgever') != null){
        var table_url = sitelink + "/dt/projecten/"+$('#projecten_table').data('opdrachtgever');
    } else {
        var table_url = sitelink + "/dt/projecten";
    }
    $('#projecten_table').DataTable({
        stateSave: true,
        responsive: false,
        "pageLength": 250,
        order: [[4, "desc"]],
        "ajax": table_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Id"},
            {title: "Projectnummer"},
            {title: "Naam"},
            {title: "Opdrachtgever"},
            {title: "Aanmaakdatum"},
            {title: "Aantal adressen"},
            {title: "<span class='min-width-actions'>Acties</span>"}
        ],
        "columnDefs": [
            {
                sClass: "hidden url",
                visible: true,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#projecten_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#projecten_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');



            var html = $('#projecten_table_filters tr');
            $('#projecten_table_filters').remove();
            $('#projecten_table > thead').append(html);
            $('#projecten_table_filters').removeClass('d-none');

            $('#projecten_table_filters_submit').on('click', function(){
                var filters = $('#projecten_table_filters_form').serializeArray();
                var new_data_url = table_url+'?'+$.param(filters);

                $('#projecten_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#projecten_table_filters_submit').click();
                }
            });
            $('#projecten_table_filters_reset').on('click', function(){
                $('#projecten_table_filters_form')[0].reset();
                $('#projecten_table').DataTable().ajax.url(table_url).load();
            });
        }
    });
}
function subprojecten_table() {
    var project_id = $('#subprojecten_table').data('projectId');
    var table_url = sitelink + "/dt/subprojecten/"+project_id;
    var table = $('#subprojecten_table')
    $('#subprojecten_table').DataTable({
        stateSave: true,
        responsive: true,
        dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,
        pageLength: 250,
        ajax: table_url,
        order: [[2, "asc"]],

        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: " <label class=\"checkbox checkbox-single\">\n" +
                    "                            <input type=\"checkbox\" value=\"\" class=\"group-checkable\"/>\n" +
                    "                            <span></span>\n" +
                    "                        </label>"},
            {title: "Id"},
            {title: "Adres"},
            {title: "Route"},
            // {title: "Startdatum"},
            // {title: "Postcode"},
            // {title: "Plaats"},
            {title: "Hoofdhuurder"},
            {title: "E-mail"},
            {title: "Status"},
            {title: "Recht op"},
            {title: "Laatste wijziging"},
            {title: "<span class='min-width-extras'>Extras</span>"},
            {title: ""}
        ],
        "columnDefs": [
            {
                visible: true,
                sortable: false,
                "targets": [0],
            },
            {
                sClass: "hidden",
                visible: true,
                "targets": [1],
                searchable: true,
            },
            {
                visible: true,
                "targets": [10],
                sortable: false,

            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "init": function () {
            initFacturenTooltip();
        },
        "initComplete": function () {
            $("#subprojecten_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#subprojecten_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#subprojecten_table_filters tr');
            $('#subprojecten_table_filters').remove();
            $('#subprojecten_table > thead').append(html);
            $('#subprojecten_table_filters').removeClass('d-none');

            $('#subprojecten_table_filters_submit').on('click', function(){
                var filters = $('#subprojecten_table_filters_form').serializeArray();
                var new_data_url = table_url+'?'+$.param(filters);
                $('#subprojecten_table').DataTable().ajax.url(new_data_url).load(function () {
                    initFacturenTooltip()
                });
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#subprojecten_table_filters_submit').click();
                }
            });
            $('#subprojecten_table_filters_reset').on('click', function(){
                $('#subprojecten_table_filters_form')[0].reset();
                $('#subprojecten_table').DataTable().ajax.url(table_url).load();
                initFacturenTooltip();
            });


            $('.group-checkable').on('click', function(){
                var state = $('.group-checkable').prop('checked');
                var checkBoxes = $(".checkable");
                checkBoxes.prop("checked", state);
            });

            initFacturenTooltip();

        }
    });
}

var initFacturenTooltip = function () {
    $('[data-toggle="facturen_tooltip"]').tooltip();

    console.log('testing met mark')
}

//// CATEGORIE TABLE
// 'use strict';
// Class definition
var CategorieTableKT = function() {
    // Private functions

    var subTableInit = function(e) {
        var categorie_dt_url = sitelink + "/dt/categorieen";


        $('<div/>').attr('id', 'child_data_local_' + e.data.Id).appendTo(e.detailCell).KTDatatable({
            data: {
                type: 'remote',
                source: {
                    read: {
                        url: categorie_dt_url+'/'+e.data.Id,
                        method: 'GET',
                        // params: {
                        //     // custom query params
                        //     query: {
                        //         generalSearch: '',
                        //         CustomerID: e.data.RecordID,
                        //     },
                        // },
                    },
                },
                pageSize: 5,
            },

            // layout definition
            layout: {
                scroll: true,
                height: 400,
                footer: false,
            },

            sortable: true,

            // columns definition
            columns: [ {
                    field: 'Id',
                    title: 'Id',
                visible: false,
                }, {
                    field: 'Name',
                    title: 'Subcategorie',
                }, {
                    field: 'Artikelen',
                    title: 'Aantal artikelen',
                },{
                field: 'Acties',
                class: 'table_actions',
                width: 100,
                title: 'Acties',
                sortable: false,
                overflow: 'visible',
                autoHide: false,

            } ],
        });
    };


    // demo initializer
    var mainTableInit = function() {
        var categorie_dt_url = sitelink + "/dt/categorieen";

        var datatable = $('#categorie_dt').KTDatatable({
            // datasource definition
            data: {
                type: 'remote',
                source: {
                    read: {
                        url: categorie_dt_url,
                        method: 'GET',

                    },
                },
                pageSize: 10, // display 20 records per page
            },

            // layout definition
            layout: {
                scroll: false,
                height: null,
                footer: false,
            },

            sortable: true,

            filterable: false,

            pagination: true,

            detail: {
                title: 'Load sub table',
                content: subTableInit,
            },

            search: {
                input: $('#kt_datatable_search_query'),
                key: 'generalSearch'
            },

            // columns definition
            columns: [
                {
                    field: 'Id',
                    class: 'column1',
                    width: 30,
                    title: '',
                    sortable: false,
                    textAlign: 'center',
                },  {
                    field: 'Name',
                    class: 'column2',
                    title: 'Categorie',
                }, {
                    field: 'Opdrachtgever',
                    class: 'column3',
                    title: 'Opdrachtgever',
                },{
                    field: 'Acties',
                    class: 'table_actions column3',
                    width: 100,
                    title: 'Acties',
                    sortable: false,
                    overflow: 'visible',
                    autoHide: false,

                } ],
            "datatable-on-ajax-done": function(){
                alert('hi');
            },

        });

        $('#kt_datatable_search_status').on('change', function() {
            datatable.search($(this).val().toLowerCase(), 'Status');
        });

        $('#kt_datatable_search_type').on('change', function() {
            datatable.search($(this).val().toLowerCase(), 'Type');
        });

        $('#kt_datatable_search_status, #kt_datatable_search_type').selectpicker();
    };

    return {
        // Public functions
        init: function() {
            var categorie_dt_url = sitelink + "/dt/categorieen";
            // init dmeo
            $(this).promise().then(()=> {

                mainTableInit();
            }).then(()=>{

                var html = $('#categorie_table_filters tr');
                $('#categorie_table_filters').remove();
                $('.datatable-table > thead').append(html);
                $('#categorie_table_filters').removeClass('d-none');

                // $('#categorie_dt').on('classChange', function(){
                //     $('.filter-1 span').width($('.column1').width());
                //     $('.filter-2 span').width($('.column2').width());
                //     $('.filter-3 span').width($('.column3').width());
                //     $('.filter-4 span').width($('.column4').width());
                // });
                var $div = $("#categorie_dt");
                var observer = new MutationObserver(function(mutations) {
                    var check = 0;
                    mutations.forEach(function(mutation) {

                        if (mutation.target.className.indexOf('datatable-loaded') > 0 && check === 0) {
                            check++;

                            $('.filter-1 ').outerWidth($('.column1').innerWidth());
                            $('.filter-2 ').outerWidth($('.column2').innerWidth());
                            $('.filter-3 ').outerWidth($('.column3').innerWidth());
                            $('.filter-4 ').outerWidth($('.column4').innerWidth());
                                $('.filter-1 span').outerWidth($('.column1').innerWidth() / 2);
                                $('.filter-2 span').outerWidth($('.column2').innerWidth() - 20);
                                $('.filter-3 span').outerWidth($('.column3').innerWidth() - 10);
                                $('.filter-4 span').outerWidth($('.column4').innerWidth() - 10);

                        }
                    });
                });
                observer.observe($div[0], {
                    attributes: true
                });
                $('#categorie_table_filters_submit').on('click', function(){
                    var filters = [
                        {
                            name: 'opdrachtgever',
                            value: $('input[name=opdrachtgever]').val(),
                        },
                        {
                            name: 'categorie',
                            value: $('input[name=categorie]').val(),
                        }
                    ]
                    var new_data_url = categorie_dt_url+'?'+$.param(filters);
                    $('#categorie_dt').KTDatatable().setDataSourceParam('url', new_data_url);
                    $('#categorie_dt').KTDatatable().load();
                });
                $('.form-filter').keypress( function(event){
                    if(event.keyCode == 13){
                        $('#categorie_table_filters_submit').click();
                    }
                });
                $('#categorie_table_filters_reset').on('click', function(){
                    $('#categorie_table_filters_form')[0].reset();
                    $('#categorie_dt').KTDatatable().setDataSourceParam('url', categorie_dt_url);
                    $('#categorie_dt').KTDatatable().load();
                });
            });
        },
    };
}();

jQuery(document).ready(function() {
    CategorieTableKT.init();
});
//// END CATEGORIE TABLE





function artikelen_table(){
    var cid = $('#artikelen_table').data('cid');
    var oid = $('#artikelen_table').data('oid');

    var artikelen_dt_url = sitelink + "/dt/artikelen/"+cid+"/"+oid;
    $('#artikelen_table').DataTable({
        stateSave: true,
        responsive: true,
        "pageLength": 250,
        order: [[0, "asc"]],
        "ajax": artikelen_dt_url,
        "lengthMenu": [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]],
        columns: [
            {title: "Naam"},
            {title: "Artikelnummer"},
            {title: "Leverancier"},
            {title: "Subcategorie"},
            {title: "Hoofdcategorie"},
            {title: "Opdrachtgever"},
            {title: "<span class='min-width-actions'>Acties</span>"},
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            $("#artikelen_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#artikelen_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#artikelen_table_filters tr');
            $('#artikelen_table_filters').remove();
            $('#artikelen_table > thead').append(html);
            $('#artikelen_table_filters').removeClass('d-none');

            $('#artikelen_table_filters_submit').on('click', function(){
                var filters = $('#artikelen_table_filters_form').serializeArray();
                var new_data_url = artikelen_dt_url+'?'+$.param(filters);

                $('#artikelen_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#artikelen_table_filters_submit').click();
                }
            });
            $('#artikelen_table_filters_reset').on('click', function(){
                $('#artikelen_table_filters_form')[0].reset();
                $('#artikelen_table').DataTable().ajax.url(artikelen_dt_url).load();
            });
        }
    });

}
